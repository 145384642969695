<template>
  <div class="main-page">
    <div class="main-content">
      <!-- 树型数据 -->
      <tree-content
        class="left_content"
        @companyChange="companyChange"
      ></tree-content>
      <!-- 表格展示项 -->
      <data-content class="right_content" ref="dataContent"></data-content>
    </div>
  </div>
</template>

<script>
import TreeContent from './components/tree-content.vue'
import DataContent from './components/data-content.vue'
export default {
  name: 'orgMain',
  components: { TreeContent, DataContent },
  data () {
    return {}
  },
  methods: {
    companyChange (item) {
      this.$refs.dataContent.refresh(item)
    }
  }
}
</script>

<style lang="scss" scoped>
.main-page {
  padding: 10px 0;
  box-sizing: border-box;
  /deep/ .el-icon-refresh{
    color: #EFC57B;
  }
  /deep/ .el-input__inner{
    border-color: #D9E5EE;
  }
  /deep/ .el-input-group__append {
    background: linear-gradient(90deg, #285ED7 0%, #749EFF 100%);
    .el-icon-search {
      color: #FFF;
    }
  }
  /deep/ .el-row{
    padding: 20px;
  }
}

.main-content {
  height: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  .left_content {
    width: 400px;
    box-sizing: border-box;
    border: 1px solid #e6e6e6;
    padding: 6px;
    background: #fff;
  }
  .right_content {
    margin-left: 16px;
    width: calc(100% - 400px);
  }
}
</style>
